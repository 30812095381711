const getCookie = (name) => {
  let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return value ? value[2] : null
}

const removeCookie = (name) => {
  let date = new Date()
  date.setDate(date.getDate() - 100)
  
  let Cookie = `${name}=;Expires=${date.toUTCString()}`
  document.cookie = Cookie+"crossCookie=bar; SameSite=None; Secure"
}

export { getCookie, removeCookie }
