import axios from 'axios'
import { getCookie } from './cookie'

axios.defaults.withCredentials = true
const instance = axios.create({
  baseURL: process.env.REACT_APP_NUBOT_BASE_URL,
})

instance.interceptors.request.use(
  function (config) {
    config.headers['access-token'] = getCookie('access_token');
    config.headers['testt']="TESTT"
    const sessionId = getCookie("session-cookie");
    if (sessionId) {
      config.headers["Cookie"] = `session-cookie=${sessionId}`;
    }
    return config
  },
  function (error) {
    alert('request error')
    return Promise.reject(error)
  }
);

instance.interceptors.response.use(
  function (response) {
    const { data } = response
    if (response.status < 300) {
      return data
    }
  },

  function (error) {
    // 오류시 처리 방법
    if (error.response) {
      const { status, config, data } = error.response
      if (status === 400) {
        alert('잘못된 데이터가 전송되었습니다. 관리자에게 문의해주세요.')
      } else if (status === 401) {
        alert('존재하지 않는 이메일입니다. 다시 확인해주세요.')
      } else if (status === 403) {
        alert('비활성화된 계정입니다. 관리자에게 문의해주세요.')
        window.location.href = '/login'
      } else if (status === 406) {
        /* Builder 페이지에서 POST /chat/release 호출 시 */
        alert('시작 블록과 연결된 블록이 없습니다.')
      } else if (status === 412) {
        /* End User 페이지에서 GET /chat/profile 호출 시 */
        alert('챗봇이 존재하지 않거나 배포되지 않았습니다.')
      } else if (status === 419 && data.reason === 'overlap') {
        /* API 호출 시 사전 세션 검증 단계에서 발생하는 오류 */
        alert('동시에 같은 챗봇을 수정할 수 없어 메인 페이지로 이동합니다.')
        window.location.href = '/main'
      } else if (status === 419 && data.reason === 'expired') {
        /* API 호출 시 사전 세션 검증 단계에서 발생하는 오류 */
        /*window.location.href = '/login'*/
      } else if (status === 500) {
        alert('서버 오류가 발생하였습니다. 관리자에게 문의해주세요.')
      } else {
        console.error(data)
      }
    }
    return Promise.reject(error)
  }
)

const api = {
  get: ({ url, query }) => {
    const params = query

    return instance({ url, params, method: 'get' })
  },
  post: ({ url, query }) => {
    const params = query

    return instance.post(url, params)
  },
  put: ({ url, query }) => {
    const params = query

    return instance.put(url, params)
  },
  delete: ({ url, query }) => {
    const params = query

    return instance.delete(url, params)
  },
  // formdata는 파일을 전송할 때 필요함
  formDataPost: ({ url, form }) => {
    return instance.post(url, form)
  },
  // formdata는 파일을 전송할 때 필요함
  formDataPut: ({ url, form }) => {
    return instance.put(url, form)
  },
}

export default api
