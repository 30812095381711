import { atom } from 'recoil'

// 커서 위치
export const cursorPosition = atom({
  key: 'cursorPosition',
  default: {
    cursorX: 0,
    cursorY: 0,
  },
})

// 커서 타입
export const cursorStatus = atom({
  key: 'cursorStatus',
  default: '',
})

// 마우스 위치에 따라 커서 아이콘 표시 여부
export const canShowCursorIcon = atom({
  key: 'canShowCursorIcon',
  default: true,
})
