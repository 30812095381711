import React, { useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

// CSS module
import classNames from 'classnames/bind'
import style from './HomeNavList.module.scss'

// Images
import {
  JonathanLogo,
  NuBotLogo,
  DatascopeLogo,
  FlightbaseLogo,
} from 'assets/img'

const cx = classNames.bind(style)

const productList = [
  {
    label: 'Jonathan',
    link: 'https://portal.acryl.ai/',
    img: JonathanLogo,
    disabled: false,
  },
  {
    label: 'Flightbase',
    link: 'https://flightbase.acryl.ai/',
    img: FlightbaseLogo,
    selected: false,
  },
  {
    label: 'NuBot',
    link: 'https://nubot.acryl.ai/',
    img: NuBotLogo,
    disabled: true,
  },
  {
    label: 'Datascope',
    link: 'https://datascope.acryl.ai/',
    img: DatascopeLogo,
    disabled: false,
  },
]

const { createPortal } = ReactDOM

const HomeNavList = ({ popupOpenHandler = () => {} }) => {
  const popup = useRef(null)

  const handleClick = (e) => {
    if (popup.current === null || !popup.current.contains(e.target)) {
      popupOpenHandler()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick, false)
    return () => {
      document.removeEventListener('click', handleClick, false)
    }
  }, [])

  const el = document.getElementById('SideNav')
  if (!el) return null

  return createPortal(
    <div ref={popup} className={cx('popup')}>
      <ul className={cx('product-list')}>
        {productList.map(({ label, link, img, disabled, selected }, idx) => (
          <li key={idx}>
            <button
              className={cx('product-btn', selected && 'selected')}
              onClick={() => {
                if (!selected) window.location.href = link
              }}
              disabled={disabled}
            >
              <div className={cx('logo-wrapper')}>
                <img className={cx('logo')} src={img} alt={label} />
              </div>
              <p className={cx('label')}>{label}</p>
            </button>
          </li>
        ))}
      </ul>
    </div>,
    el
  )
}

export default HomeNavList
