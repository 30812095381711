import React from 'react'

// Components
import SideNav from 'components/SideNav'

// CSS module
import styled from 'styled-components'

const PageTemplate = ({ children }) => {
  return (
    <PageContainer>
      <SideNav></SideNav>
      {children}
    </PageContainer>
  )
}

const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export default PageTemplate
