import React, { useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

// CSS module
import style from './UserSettingsPopup.module.scss'
import classNames from 'classnames/bind'

// Images
import { FlightbaseLogo, DatascopeLogo, NuBotLogo } from 'assets/img'
import { useRecoilValue } from 'recoil'
import { userProfile } from 'recoils/userProfileState'

import api from 'utils/api.js'

const cx = classNames.bind(style)

const manualOptions = [
  {
    name: 'flightbase',
    disabled: false,
  },
  {
    name: 'datascope',
    disabled: true,
  },
  {
    name: 'nubot',
    disabled: true,
  },
]

const UserSettingsPopup = ({ popupOpenHandler = () => {} }) => {
  const popup = useRef(null)
  const handleClick = (e) => {
    if (popup.current === null || !popup.current.contains(e.target)) {
      popupOpenHandler()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick, false)
    return () => {
      document.removeEventListener('click', handleClick, false)
    }
  }, [])
  const _userProfile = useRecoilValue(userProfile)

  const onServiceManual = (service) => {
    const link = document.createElement('a')
    if (service === 'flightbase') {
      link.href = 'https://portal.acryl.ai/manual/Flightbase_User_Guide.pdf'
      link.download = 'fb_manual.pdf'
      link.target = '_blank'
    }
    link.click()
    link.remove()
  }

  const capitalizeFirstLetter = (s) => {
    return !s || typeof s !== 'string' || s.length <= 0
      ? s
      : s[0].toUpperCase() + s.slice(1)
  }

  return (
    <div ref={popup} className={cx('popup')}>
      <div className={cx('top-box')}>
        <div className={cx('user-info')}>
          <div className={cx('hi-logout')}>
            <span className={cx('hi')}>안녕하세요!</span>
            <button
              className={cx('logout-btn')}
              onClick={async () => {
                try {
                  await api.delete({ url: '/user/session' })
                  window.location.href = 'https://portal.acryl.ai/member/logout'
                } catch (err) {
                  console.log(err)
                }
              }}
            >
              로그아웃
            </button>
          </div>
          <div className={cx('user-name')}>
            <span className={cx('name')}>
              {capitalizeFirstLetter(_userProfile?.username)}
            </span>
            <span className={cx('sir')}>님</span>
          </div>

          <button
            className={cx('password-btn')}
            onClick={() => (window.location.href = 'https://portal.acryl.ai')}
          >
            대시보드로 이동하기
          </button>
        </div>
      </div>
      <div className={cx('manual-box')}>
        {manualOptions.map(
          ({ name, disabled, hidden }) =>
            !hidden && (
              <div
                key={name}
                className={cx('manual-item', disabled && 'disabled')}
                onClick={() => onServiceManual(name)}
              >
                <span className={cx('service-manual')}>Service manual</span>
                <span className={cx('service-name')}>{name}</span>
                <img
                  className={cx('service-icon', name)}
                  src={
                    name === 'flightbase'
                      ? FlightbaseLogo
                      : name === 'datascope'
                      ? DatascopeLogo
                      : NuBotLogo
                  }
                  alt={name}
                />
              </div>
            )
        )}
      </div>
      <div className={cx('delete-account-box')}></div>
    </div>
  )
}

export default UserSettingsPopup
