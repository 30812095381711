import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

// CSS module
import style from '../SideNav.module.scss'
import classNames from 'classnames/bind'

// Images
import { arrowUp, Home } from 'assets/img'

const cx = classNames.bind(style)

const MainSideNav = ({ className, isBuilderExpand, setIsBuilderExpand }) => {
  const NavConstants = [
    {
      path: 'main',
      title: '홈',
      logo: Home,
      isSub: false,
      hasArrow: false,
    },
    // {
    //   path: 'manual',
    //   title: '챗봇 메뉴얼',
    //   logo: Home,
    //   isSub: true,
    //   hasArrow: false,
    // },
  ]

  return (
    <>
      {NavConstants.filter((x) => {
        if (isBuilderExpand) return x
        else return x.isSub === isBuilderExpand
      }).map((nav, i) => (
        <NavLink
          key={`navitem-${i}`}
          to={`/${nav.path}`}
          className={cx('nav-title', {
            'sub-item': nav.isSub,
          })}
          activeStyle={{
            background: '#0421BD',
          }}
        >
          <img className={cx('nav-type-ico')} src={nav.logo} alt={nav.path} />
          <span className={cx('nav-title-text')}>{nav.title}</span>
          {nav.hasArrow && (
            <img
              className={cx('nav-updown-ico', !isBuilderExpand && 'expand')}
              src={arrowUp}
              alt="up down"
              onClick={(e) => {
                e.preventDefault()
                setIsBuilderExpand(!isBuilderExpand)
              }}
            />
          )}
        </NavLink>
      ))}
    </>
  )
}

export { MainSideNav }
