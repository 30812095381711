import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

// CSS module
import style from '../SideNav.module.scss'
import classNames from 'classnames/bind'

// Images
import { arrowUp, Door } from 'assets/img'

import { GNB } from 'common/constants'

const cx = classNames.bind(style)

const BasicSideNav = ({ isBuilderExpand, setIsBuilderExpand }) => {
  const { short_id } = useParams()

  return (
    <>
      <NavLink to={`/main`} className={cx('nav-title', 'exit')}>
        <img className={cx('nav-type-ico')} src={Door} />
        <span className={cx('nav-title-text')}>대시보드로 나가기</span>
      </NavLink>
      {GNB.filter((x) => {
        if (isBuilderExpand) return x
        else return x.isSub === isBuilderExpand
      }).map((nav, i) => (
        <NavLink
          key={`navitem-${i}`}
          to={`/${nav.to}/${short_id}`}
          className={cx('nav-title', {
            'sub-item': nav.isSub,
          })}
          activeStyle={{
            background: '#365fff',
          }}
        >
          <img className={cx('nav-type-ico')} src={nav.subImg} alt={nav.to} />
          <span className={cx('nav-title-text')}>{nav.title}</span>
          {nav.hasArrow && (
            <img
              className={cx('nav-updown-ico', !isBuilderExpand && 'expand')}
              src={arrowUp}
              alt="up down"
              onClick={(e) => {
                e.preventDefault()
                setIsBuilderExpand(!isBuilderExpand)
              }}
            />
          )}
        </NavLink>
      ))}
    </>
  )
}

export { BasicSideNav }
