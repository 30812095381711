// images
import {
  Builder,
  Statistics,
  Link,
  Setting,
  BuilderSub,
  TriggerIcon,
  StatisticsSub,
  LinkSub,
  SettingSub,
} from 'assets/img'

export const GNB = [
  {
    title: '빌더',
    img: Builder,
    subImg: BuilderSub,
    to: `builder`,
    isSub: false,
    hasArrow: true,
  },
  {
    title: '트리거 관리자',
    subImg: TriggerIcon,
    to: 'manage',
    isSub: true,
    hasArrow: false,
  },
  {
    title: '통계',
    img: Statistics,
    subImg: StatisticsSub,
    to: `statistics`,
    isSub: false,
    hasArrow: false,
  },
  {
    title: '연동',
    img: Link,
    subImg: LinkSub,
    to: `link`,
    isSub: false,
    hasArrow: false,
  },
  {
    title: '설정',
    img: Setting,
    subImg: SettingSub,
    to: `setting`,
    isSub: false,
    hasArrow: false,
  },
]

export const LINKING_LIST = [
  {
    title: 'URL',
    desc: '챗봇을 사용할 수 있는 링크를 받을 수 있습니다.',
  },
  {
    title: '카카오톡',
    desc: 'Coming Soon',
  },
  {
    title: '페이스북',
    desc: 'Coming Soon',
  },
]

export const TRIGGER_TYPE = {
  WORD: [
    {
      kr: '단어',
      en: 'KEYWORD',
      input: true,
    },
    {
      kr: 'AI 단어',
      en: 'NER',
    },
  ],
  SENTENCE: [
    {
      kr: '정규식',
      en: 'REG',
      input: true,
    },
    {
      kr: '육하원칙',
      en: 'OWIH',
    },
    {
      kr: '의도',
      en: 'IU',
    },
    {
      kr: '감성',
      en: 'TE',
    },
  ],
  IMAGE: [
    {
      kr: '욕창',
      en: 'BEDSORE',
    },
    {
      kr: '갑상선',
      en: 'THYROID',
    },
  ],
}

export const TRIGGER_DETAIL = {
  NER: {
    text: 'AI 단어 인식',
    desc: 'AI 엔진을 사용하여 메시지를 인식할 수 있습니다.',
  },
  IU: {
    text: '의도',
    desc: 'AI 엔진을 사용하여 문장에서 느껴지는 육하원칙을 인식할 수 있습니다.',
  },
  OWIH: {
    text: '육하원칙',
    desc: 'AI 엔진을 사용하여 문장에서 느껴지는 육하원칙을 인식할 수 있습니다.',
  },
  TE: {
    text: '감성',
    desc: 'AI 엔진을 사용하여 문장에서 느껴지는 감성을 인식할 수 있습니다.',
  },
  BEDSORE: {
    text: '욕창 인식',
    desc: 'AI 엔진을 사용하여 이미지로부터 욕창을 인식할 수 있습니다.',
  },
  THYROID: {
    text: '갑상선 인식',
    desc: 'AI 엔진을 사용하여 이미지로부터 갑상선 상태를 인식할 수 있습니다.',
  },
}

export const DefaultResouceParam = {
  text: '',
  desc: '',
  button: {
    direction: 'Row',
    elements: [],
  },
  image: {},
  api: {
    api_type: 'Api',
    method: 'GET',
    name: '',
    payload: [],
    trigger_groups: [],
    url: '',
  },
  calendar: {
    view_type: 'DateTime',
    time_range_type: 'Full',
    specified_range: '09:00~18:00',
    time_interval: 30,
    past_selectable: false,
    text: '',
    title: '',
    api_integration: false,
    apis: [
      {
        api_type: 'CalendarInquiry',
        method: 'GET',
        name: '',
        payload: [],
        trigger_groups: [],
        url: '',
      },
      {
        api_type: 'CalendarChoice',
        method: 'GET',
        name: '',
        payload: [],
        trigger_groups: [],
        url: '',
      },
    ],
  },
}
