import React, { useEffect, useState } from 'react'

// Components
import HomeNav from './HomeNav'
import NavPopupButton from './UserSettings/NavPopupButton'
import { BasicSideNav } from './BasicSideNav/BasicSideNav'
import { MainSideNav } from './MainSideNav/MainSideNav'

// CSS module
import style from './SideNav.module.scss'
import classNames from 'classnames/bind'

// Images
import {
  NuBotLogoText,
  NuBotLogo,
  bigArrowLeft,
  FlightbaseLogo,
} from 'assets/img'

// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userProfile } from 'recoils/userProfileState'
import { navExpand } from 'recoils/sideNavState'
import { isMainPage } from 'recoils/isMainPageState'
import { canShowCursorIcon } from 'recoils/cursorState'

const cx = classNames.bind(style)

const SideNav = () => {
  const [isExpand, setIsExpand] = useRecoilState(navExpand) // 네비게이션 확장 여부
  const [isBuilderExpand, setIsBuilderExpand] = useState(true) // 빌더 nav 확장 여부
  const _userProfile = useRecoilValue(userProfile)
  const _isMainPage = useRecoilValue(isMainPage)
  const setShowCursorIcon = useSetRecoilState(canShowCursorIcon)

  // 네비게이션 확장 이벤트 핸들러
  const handleIsExpand = () => {
    if (isExpand) {
      setIsBuilderExpand(true)
    }
    setIsExpand(!isExpand)
  }

  // 페이지 전환 시 배경색 문제 해결
  useEffect(() => {
    if (_isMainPage) {
      document.body.style.background = '#ffffff'
    } else {
      document.body.style.background = '#1b1c20'
    }
  }, [_isMainPage])

  return (
    <div
      id="SideNav"
      className={cx(
        'gnb',
        _isMainPage ? ['expand', 'main'] : isExpand ? 'expand' : 'hide'
      )}
      onMouseEnter={() => setShowCursorIcon(false)}
      onMouseLeave={() => setShowCursorIcon(true)}
    >
      <div className={cx('home')}>
        {isExpand || _isMainPage ? (
          <img
            src={NuBotLogoText}
            alt={'Jonathan NuBot'}
            onClick={() => (window.location.href = '/main')}
          />
        ) : (
          <img
            src={NuBotLogo}
            alt={'Jonathan NuBot'}
            onClick={() => (window.location.href = '/main')}
          />
        )}
        <HomeNav isExpand={isExpand} />
      </div>
      <div className={cx('user-nav')}>
        <div className={cx('nav-box-wrap')}>
          <div className={cx('nav-box')}>
            {_isMainPage ? (
              <MainSideNav
                isBuilderExpand={isBuilderExpand}
                setIsBuilderExpand={setIsBuilderExpand}
              />
            ) : (
              <BasicSideNav
                isBuilderExpand={isBuilderExpand}
                setIsBuilderExpand={setIsBuilderExpand}
              />
            )}
          </div>
        </div>
      </div>
      {/* <NavPopupButton type="ENGINE">
          <div className={cx('user-box')}>
            <button className={cx('user-btn')}>
              <img
                className={cx('user-initial')}
                src={FlightbaseLogo}
                alt="fblogo"
              />
              <span className={cx('user-name')}>Flightbase AI 트리거</span>
              <img
                className={cx('arrow-icon')}
                src={bigArrowLeft}
                alt="arrow-icon"
              />
            </button>
          </div>
        </NavPopupButton> */}

      <NavPopupButton type="USER_SETTING">
        <div className={cx('user-box')}>
          <button className={cx('user-btn')}>
            <span className={cx('user-initial', 'circle')}>
              {_userProfile
                ? _userProfile.username.slice(0, 2).toUpperCase()
                : ''}
            </span>
            <span className={cx('user-name')}>{_userProfile?.username}</span>
            <img
              className={cx('arrow-icon')}
              src={bigArrowLeft}
              alt="arrow-icon"
            />
          </button>
        </div>
      </NavPopupButton>
      <div
        className={cx('fold-box', isExpand && 'open')}
        onClick={() => handleIsExpand('isExpand')}
      ></div>
    </div>
  )
}

export default SideNav
