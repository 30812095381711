import React, { Suspense, useState, useEffect, lazy } from 'react'
// Plugin
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

// Components
import PageTemplate from 'components/PageTemplate'

// route pages
import { RecoilRoot, useSetRecoilState } from 'recoil'
import { userProfile } from 'recoils/userProfileState'

// styles
import 'assets/css/Reset.scss'
import 'assets/css/Common.scss'

// utils
import api from 'utils/api.js'

const LoginPage = lazy(() => import('pages/LoginPage'))
const MainPage = lazy(() => import('pages/MainPage'))
const BuilderPage = lazy(() => import('pages/BuilderPage'))
const MessengerLinkPage = lazy(() => import('pages/MessengerLinkPage'))
const StatisticsPage = lazy(() => import('pages/StatisticsPage'))
const SettingPage = lazy(() => import('pages/SettingPage'))
const EndUserPage = lazy(() => import('pages/EndUserPage'))
const TriggerAdminPage = lazy(() => import('pages/TriggerAdminPage'))
const Toast = lazy(() => import('components/Toast/Toast'))

// 로그인 체크 후 화면 진입하는 route
const PrivateRoute = ({ component: Component, ...rest }) => {
  // 처음에 api 쏴보고 문제가 있는지 확인한후 렌더링하도록 한다
  const [isDistComplete, setIsDistComplete] = useState(false)
  const setUserProfile = useSetRecoilState(userProfile)

  // GET /user/profile이 터지면 다른 것들은 쏘지 않음
  const getUserProfile = async () => {
    try {
      const res = await api.get({
        url: '/user/profile',
      })
      setUserProfile(res)
      setIsDistComplete(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserProfile()
    // 페이지가 다시 보여질 때 단순 세션 체크를 위한 API 호출
    document.addEventListener('visibilitychange', async (e) => {
      if (!e.target.hidden) {
        try {
          await api.get({
            url: '/user/check',
          })
        } catch (err) {
          console.log(err)
        }
      }
    })
  }, [])

  return (
    <>
      {isDistComplete && (
        <Route
          {...rest}
          render={(props) => (
            <PageTemplate>
              <Component {...props} />
            </PageTemplate>
          )}
        />
      )}
    </>
  )
}

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          {/* 빈 주소값일 경우 임시 로그인 페이지로 redirect */}
          <Route exact path="/">
            <Redirect to="/main" />
          </Route>
          {/* 임시 로그인 페이지 라우터 */}
          <Route
            path="/login"
            component={
              process.env.NODE_ENV === 'development'
                ? LoginPage
                : LoginPage
            }
          />
          {/* 메인페이지 */}
          <PrivateRoute path="/main" component={MainPage} />
          {/* end user View */}
          <Route path="/nubot/:short_id" component={EndUserPage} />
          {/* 트리거 관리자 */}
          <PrivateRoute path="/manage/:short_id" component={TriggerAdminPage} />
          {/* builder page */}
          <PrivateRoute path="/builder/:short_id" component={BuilderPage} />
          {/* 메신저 연동 */}
          <PrivateRoute path="/link/:short_id" component={MessengerLinkPage} />
          {/* 통계 */}
          <PrivateRoute
            path="/statistics/:short_id"
            component={StatisticsPage}
          />
          {/* 설정 */}
          <PrivateRoute path="/setting/:short_id" component={SettingPage} />
        </Switch>
      </Router>
      <Toast />
    </>
  )
}

const Root = () => {
  return (
    <RecoilRoot>
      <Suspense fallback={<div></div>}>
        <App />
      </Suspense>
    </RecoilRoot>
  )
}

export default Root
