import React, { useState } from 'react'

// Components
import UserSettingsPopup from './UserSettingsPopup'

// CSS module
import classNames from 'classnames/bind'
import style from './NavPopupButton.module.scss'
import EnginePopup from './EnginePopup'
const cx = classNames.bind(style)

const NavPopupButton = ({ children, type }) => {
  const [isOpen, setIsOpen] = useState(false)

  const popupHandler = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={cx('user-settings')}>
      <div onClick={popupHandler}>{children}</div>
      {isOpen && (
        <>
          {type === 'USER_SETTING' && (
            <UserSettingsPopup popupOpenHandler={popupHandler} />
          )}
          {type === 'ENGINE' && <EnginePopup popupOpenHandler={popupHandler} />}
        </>
      )}
    </div>
  )
}

export default NavPopupButton
