import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

// Components
import HomeNavList from './HomeNavList'

// CSS module
import classNames from 'classnames/bind'
import style from './HomeNav.module.scss'

// Images
import { ProductsMenuIcon } from 'assets/img'

// recoil
import { navExpand } from 'recoils/sideNavState'
import { isMainPage } from 'recoils/isMainPageState'

const cx = classNames.bind(style)

const HomeNav = () => {
  const _isMainPage = useRecoilValue(isMainPage)

  const _isExpand = useRecoilValue(navExpand)
  const [isOpen, setIsOpen] = useState(false)

  const tooltipHandler = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={cx('home-nav')}>
      {_isExpand || _isMainPage ? (
        <button className={cx('home-btn')} onClick={tooltipHandler}>
          <img src={ProductsMenuIcon} alt="Jonathan Products" />
        </button>
      ) : null}
      {isOpen && <HomeNavList popupOpenHandler={tooltipHandler} />}
    </div>
  )
}

export default HomeNav
