import React, { useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

// CSS module
import style from './EnginePopup.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(style)

// 향후 켜져있는 엔진리스트를 아래와 비슷한 포맷으로 받는다고 가정
const engineStatus = [
  {
    type: 'text',
    items: [
      {
        type: 'NER',
        text: '개체명',
        status: true,
      },
      {
        type: 'TE',
        text: '감성',
        status: true,
      },
      {
        type: 'IU',
        text: '의도',
        status: true,
      },
    ],
  },
  {
    type: 'image',
    items: [
      {
        type: 'BEDSORE',
        text: '욕창',
        status: true,
      },
      {
        type: 'THYROID',
        text: '갑상선',
        status: false,
      },
    ],
  },
]

const EnginePopup = ({ popupOpenHandler = () => {} }) => {
  const popup = useRef(null)

  const handleClick = (e) => {
    if (popup.current === null || !popup.current.contains(e.target)) {
      popupOpenHandler()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick, false)
    return () => {
      document.removeEventListener('click', handleClick, false)
    }
  }, [])

  return (
    <div ref={popup} className={cx('popup')}>
      <h5>NuBot에서 사용 가능한 AI 트리거</h5>
      {engineStatus.map((x, i) => (
        <div key={`enginepopup-${i}`}>
          <span>{x.type === 'text' ? '텍스트' : '이미지'} 기반 AI 트리거</span>
          <div className={cx('engine-item-wrap')}>
            {x.items.map((x1, i) => (
              <div
                className={cx('engine-item', {
                  disabled: !x1.status,
                })}
              >
                {x1.text} 인식
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className={cx('popup-footer')}>
        <pre>{`Jonathan Flightbase에서 AI 모델을 배포하고,\nNuBot에서 사용해보세요!`}</pre>
        <a
          href="https://flightbase.acryl.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          AI 모델 배포하러 가기
        </a>
      </div>
    </div>
  )
}

export default EnginePopup
